.codeBlock {
  border: 1px solid #444;
  background-color: #2e3440!important;
  color: #f8f8f2;
  padding: 1em;
  border-radius: 5px;
  overflow: auto;
}

.itemContainer {
  margin-bottom: 10px;
}

.accordionHeader {
  scroll-margin-top: 130px;
}

.sectionContainer {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #6C6C6C;
  border-radius: 0 0 3px 3px;
  padding: 15px;
}
