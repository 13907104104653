@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-brand {
  text-decoration: none !important;
}

.app-toaster {
  position: fixed !important;
}

.bp4-button-text {
  overflow: hidden;
}
.bp4-select-popover .bp4-popover-content,
.bp4-select-popover .bp4-popover2-content {
  padding: 5px;
}
.bp4-select-popover .bp4-menu {
  max-height: 300px;
  max-width: 400px;
  overflow: auto;
  padding: 0;
}
.bp4-select-popover .bp4-menu:not(:first-child) {
  padding-top: 5px;
}
