.calloutContainer {
  position: sticky !important;
  top: 30px;
  border-bottom: 1px solid #787878;
  margin: -15px -15px 15px -15px;
  width: auto !important;
  z-index: 2;
}

.successContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px !important;
  background-color: #D6E0DA !important;
}

:global(.dark) .successContainer {
  background-color: #2B3F32 !important;
}

.errorContainer {
  background-color: #E7DAD9 !important;
}

:global(.dark) .errorContainer {
  background-color: #4C3032 !important;
}

.errorsList {
  margin: 0;
  padding-left: 15px;
}

main:has(> .container) {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-grow: 1;
  max-height: calc(200vh);
}

.containerRelative {
  position: relative;
}

.containerAbsolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editorRow {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.runFieldEditorOuter {
  display: flex;
  flex-direction: column;
  flex-basis: 25%;
}

.runSelectContainer {
  margin-bottom: 5px;
}

.runFieldSelectButton {
  justify-content: space-between !important;
}

.editorContainer {
  height: 100%;
}

.editorLabel {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  background-color: #A3A3A345;
  z-index: 1;
}

.prettyPrintButton {
  position: absolute;
  top: 30px;
  right: 5px;
  z-index: 1;
}

.runFieldEditor,
.ruitEditor {
  position: relative;
}

.runFieldEditor {
  height: 100%;
  overflow: scroll;
}

.ruitEditor {
  flex-grow: 1;
  height: 100%;
}

.previewContainer {
  overflow: auto !important;
}

.runFieldEditor,
.ruitEditor {
  border: 1px solid #C1C1C1;
}

.selectFormGroup {
  display: flex;
}

.ruitSubmitButton {
  width: 75px;
}

/**
 * Editor styles
 */
.errorLine {
  background-color: #FF363645 !important;
  /* Important to override the theme styles */
}

.runField {
  text-decoration: underline 2px #B3B3B3;
}

.selectedrunField {
  text-decoration: underline 2px #FFF;
}

.missingrunField {
  text-decoration: underline 2px #DE6060;
}

.runFieldTooltip {
  padding: 15px;
  border: 1px solid #6C6C6C;
  border-radius: 3px;
  max-height: 400px;
  overflow-y: scroll;
}

.titleBar {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #787878;
  background-color: #EAEAEA;
}

:global(.dark) .titleBar {
  background-color: #2C2C2C;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 15px;
  width: 100%;
}

.titleColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 320px;
}

.titleLabel {
  font-size: 0.9em;
}

.titleValue {
  font-size: 1.5em;
  overflow: ellipsis;
}

.resizeHandleContainer {
  position: relative;
  margin: 5px 0;
  outline: none !important;
}

.resizeDivider {
  box-shadow: 0 3px 5px #AEAEAE;
}

.resizeHandle {
  position: absolute;
  top: -10px;
  left: 48%;
  border: 1px solid #CACACA;
  border-radius: 100%;
  padding: 7px;
  background-color: #EAEAEA;
  box-shadow: 0px 3px 4px #D4D4D4;
  cursor: ns-resize;
}

/**
 * Dark mode styles
 */
.dark .runFieldEditor,
.dark .ruitEditor {
  border: 1px solid #6C6C6C;
}

.dark .editorLabel {
  background-color: #4848486B;
}

.dark .resizeDivider {
  box-shadow: 0 3px 5px black;
}

.dark .resizeHandle {
  border-color: #565656;
  background-color: #2C2C2C;
  box-shadow: 0px 3px 4px #222;
}
