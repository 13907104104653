.heading {
  margin-bottom: 15px !important;
}

.override {
  text-decoration: underline;
}

.table {
  width: 100%;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.drag {
  width: 3%;
}

.columnTitle {
  margin-right: 5px;
}

.nameText {
  margin-right: 5px;
}

.type {
  width: 5%;
}

.description,
.path {
  width: 15%;
}

.min,
.max {
  width: 3%;
}

.minInput,
.maxInput {
  width: 75px !important;
}

.required {
  width: 5%;
}

.remove {
  width: 3%;
}

.dragHandle {
  cursor: grab;
}

.defaultsList {
  list-style-type: disclosure-closed;
  margin-bottom: 0;
  padding-left: 15px;
}

.defaultsList > li {
  margin-bottom: 5px;
}

.defaultValue {
  margin-right: 5px;
}

.requiredCheckbox {
  margin: 0 !important;
  text-align: center;
}
