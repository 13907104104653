.collapseHeader {
  justify-content: space-between !important;
}

.collapseHeader > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.collapseIcon {
  transition: all 0.2s ease;
}

.collapseIcon.open {
  transform: rotate(90deg);
}
