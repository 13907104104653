.navContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
  min-width: 250px;
}

.menuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.menu {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid lightgray;
  padding: 0 !important;
  background-color: white !important;
}

:global(.dark) .menu {
  background-color: #5F6B7C26 !important;
  border: 1px solid grey;
}

.menuItem {
  padding: 5px 15px !important;
}

.breadcrumbsContainer {
  max-width: 275px;
}
