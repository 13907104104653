.simProgressHeader {
  position: fixed;
  display: flex;
  justify-content: right;
  font-size: small;
  top: 2px;
  right: 140px;
  width: 600px;
  font-size: small;
  z-index: 1031;
  text-wrap: nowrap;
  cursor:pointer;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.simProgressButton {
  font-size: small;
  margin: 0px 7px 0px 7px;
  width: 140px;
}

.simProgressText {
  margin: 0px 5px 0px 5px;
  padding-top: 3px;
}