.mainForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.propertyList {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 15px;
}

.propertyFormGroup {
  justify-content: space-between;
}

.propertyFormGroupContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.noProperties {
  margin: 30px 15px;
  width: 300px !important;
  height: 126px !important;
}

.coordinateContainer {
  display: flex;
  gap: 5px;
}

.coordinateInput {
  width: 120px !important;
}

.partConfigSelectTarget {
  width: fit-content;
}

.columnsContainer {
  display: flex;
  gap: 15px;
  height: calc(100vh - 102px);
}

.inputsColumn {
  flex: 0 0 400px;
  overflow-y: scroll;
  padding: 0 15px;
}

.editorColumn {
  flex: 1;
}

.editorContainer {
  height: calc(100vh - 140px);
}

.dataEditor {
  position: relative;
}

.editorContainer {
  padding: 1px;
  border: 1px solid grey;
}

:global(.bp4-intent-danger) .editorErrors {
  border: 1px solid #AC2F33;
}

:global(.dark) :global(.bp4-intent-danger) .editorContainer {
  border: 1px solid #FA999C;
}

.errorLine {
  background-color: #FF363645 !important;
  /* Important to override the theme styles */
}

.prettyPrintButton {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
}
