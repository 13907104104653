.headerBar {
  padding-bottom: 15px;
  border-bottom: 1px solid #787878;
  display: flex;
  justify-content: space-between;
}

.headerBarRight {
  display: flex;
  align-items: center;
}

.layoutSwitch {
  margin-bottom: 0!important;
  margin-right: 10px;
}

.emptyMainSection {
  padding-top: 10px;
}

.runsContainer {
  margin-bottom: 10px;
  margin-top: 10px;
}

.runContainer {
  display: flex;
  flex-direction: column;
}

.runHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.verticalCompareContainer {
  display: flex;
  width: 100%;
  height: calc(100vh - 108px);
}

.baselineRunLogContainer {
  overflow-y: auto;
  margin-right: 15px;
  min-width: 575px;
}

.comparedRunLogsContainer {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
}

.comparedRunLogsContainer::-webkit-scrollbar-corner {
  background-color: transparent;
}

.comparedRunLogContainer {
  min-width: 353px;
}
