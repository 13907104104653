.calloutContainer {
  position: sticky !important;
  top: 30px;
  border-bottom: 1px solid #787878;
  margin: -15px -15px 15px -15px;
  width: auto !important;
  z-index: 2;
}

.successContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px !important;
  background-color: #D6E0DA !important;
}

:global(.dark) .successContainer {
  background-color: #2B3F32 !important;
}

.errorContainer {
  background-color: #E7DAD9 !important;
}

:global(.dark) .errorContainer {
  background-color: #4C3032 !important;
}

.errorsList {
  margin: 0;
  padding-left: 15px;
}

main:has(> .container) {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  gap: 15px;
  flex-grow: 1;
  max-height: calc(200vh);
}

.containerRelative {
  position: relative;
}

.containerAbsolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editorColumn {
  width: 45%;
  /* Set width because flex properties below don't seem to be respected */
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
}

.setupSelectContainer {
  margin-bottom: 5px;
}

.specSelectButton {
  width: 75px;
  justify-content: space-between !important;
}

.setupFieldSelectButton {
  justify-content: space-between !important;
}

.editorContainer {
  height: 100%;
}

.editorLabel {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  background-color: #A3A3A345;
  z-index: 1;
}

.prettyPrintButton {
  position: absolute;
  top: 30px;
  right: 5px;
  z-index: 1;
}

.setupFieldEditor,
.suitEditor {
  position: relative;
}

.setupFieldEditor {
  margin-bottom: 15px;
  height: 200px;
}

.suitEditor {
  flex-grow: 1;
  overflow-y: auto;
}

.previewContainer {
  width: 55%;
  flex: 1 1 55%;
  padding: 15px;
  background-color: #FFF;
  overflow-y: auto;
}

.setupFieldEditor,
.suitEditor,
.previewContainer {
  border: 1px solid #C1C1C1;
}

.selectFormGroup {
  display: flex;
}

.suitSubmitButton {
  width: 75px;
}

/**
 * Editor styles
 */
.errorLine {
  background-color: #FF363645 !important;
  /* Important to override the theme styles */
}

.setupField {
  text-decoration: underline 2px #B3B3B3;
}

.selectedSetupField {
  text-decoration: underline 2px #FFF;
}

.missingSetupField {
  text-decoration: underline 2px #DE6060;
}

.setupFieldTooltip {
  padding: 15px;
  border: 1px solid #6C6C6C;
  border-radius: 3px;
  max-height: 400px;
  overflow-y: scroll;
}

.titleBar {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #787878;
  background-color: #EAEAEA;
}

:global(.dark) .titleBar {
  background-color: #2C2C2C;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 15px;
  width: 100%;
}

.titleColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 320px;
}

.titleLabel {
  font-size: 0.9em;
}

.titleValue {
  font-size: 1.5em;
  overflow: ellipsis;
}

/**
 * Dark mode styles
 */
.dark .setupFieldEditor,
.dark .suitEditor,
.dark .previewContainer {
  border: 1px solid #6C6C6C;
}

.dark .editorLabel {
  background-color: #4848486b;
}

.dark .previewContainer {
  background-color: #1E1E1E;
}
