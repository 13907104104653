.actionsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.marginBottom {
  margin-bottom: 40px;
}

.reportingTableContainer {
  min-height: 300px;
}
