:root {
  --gap-dist: 20px;
}

.titleDropdown {
  display: flex;
  align-items: center;
  flex-basis: 30%;
}

.titleDropdown .label {
  display: inline-block;
  margin-right: 10px;
}

.titleDropdown .select {
  display: flex;
  flex-basis: 70%;
}

.controlsContainer {
  min-width: 150px;
  justify-content: flex-end;
  display: flex;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 98px);
}

.chartContainer {
  display: flex;
  flex-direction: column;
  gap: var(--gap-dist);
  margin-top: 30px;
}

.chartRow {
  display: flex;
  flex-direction: row;
  gap: var(--gap-dist);
}

.singleChartContainer {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
}

.chartHeader {
  display: flex;
  background-color: #383e47;
  padding-left: 5px;
}

.chartMainSection {
  display: flex;
  flex: 1;
  padding: 5px;
  border: 1px solid #6c6c6c;
  border-radius: 0 0 3px 3px;
  border-top: 0 solid #6c6c6c;
}

.chart {
  flex: 1;
  min-height: 300px;
}

.fieldContainer {
  width: 145px;
}

.fieldLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
}

.fieldLabel {
  flex: 1;
  font-size: 0.8em;
}

.fieldValue {
  width: 50px;
}

.partSelector {
  flex: 1 1 auto !important;
  max-width: 350px;
}

.partSelector button {
  height: 20px;
}

.chartTitle {
  align-self: center;
  padding-right: 5px;
}

.importSelector {
  flex: 1 1 auto !important;
  justify-content: end;
  display: flex;
}

.chartHeaderButton {
  margin-left: 5px;
}

.loadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
