.simDocumentFormContainer {
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.simDocumentFormContainer::-webkit-scrollbar {
  display: none;
}

.headerBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
