.cardContainer {
  display: flex;
  flex-direction: column;
}

.runCardHeaderContainer {
  margin-top: 10px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
}

.runCardHeader {
  display: flex;
  margin-bottom: 0!important;
  align-items: center;
}

.runCard {
  margin-right: 15px;
  height: fit-content;
}

.runCard.baseline {
  min-width: 550px;
}

.fieldInputGroup {
  align-items: center !important;
  gap: 5px;
  margin-bottom: 5px;
}

.fieldLabel {
  width: 180px;
  margin-bottom: 0 !important;
  margin-right: 10px !important;
  overflow: scroll;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  text-overflow: ellipsis;
}

.fieldLabel::-webkit-scrollbar {
  display: none;
}

.fieldCheckbox {
  margin: 0 0 0 5px !important;
}

.positionFieldsContainer {
  display: grid;
  flex: 1!important;
  gap: 2px;
}

.positionFieldsContainer.positions1 {
  grid-template-columns: 1fr;
}

.positionFieldsContainer.positions2,
.positionFieldsContainer.positions4,
.positionFieldsContainer.positions6 {
  grid-template-columns: 1fr 1fr;
}

.positionFieldsContainer.positions5 {
  grid-template-areas:
    "first first";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.positionFieldsContainer.positions5 > *:first-child {
  grid-area: first;
}

.positionFieldsContainer.positions6 {
  grid-template-rows: 1fr 1fr 1fr;
}

.highlight {
  background-color: orange !important;
}

.highlight input {
  color: black !important;
}

textarea.highlight {
  color: black !important;
}