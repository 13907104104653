.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.selectAllCheckbox {
  position: absolute !important;
  top: -25px;
}

.setupCard {
  min-width: 550px;
  max-width: 550px;
  margin-right: 15px;
  height: fit-content;
}

.setupCard:last-child {
  margin-right: 0;
}

.compareSetup {
  min-width: 350px;
  max-width: 350px;
}

.setupLabel {
  margin-bottom: 20px !important;
  text-align: center;
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.setupLabel::-webkit-scrollbar {
  display: none;
}

.itemContainer {
  margin: 15px 0;
}

.itemContainer:first-of-type {
  margin-top: 0;
}

.itemContainer:last-of-type {
  margin-bottom: 0;
}

.sectionContainer {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #6C6C6C;
  border-radius: 0 0 3px 3px;
  padding: 10px;
}

.accordionButton {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fieldInputGroup {
  align-items: center !important;
  gap: 5px;
  margin-bottom: 5px;
  min-height: 30px;
}

.fieldInputGroup:last-child {
  margin-bottom: 0;
}

.fieldLabel {
  width: 252px;
  margin-bottom: 0 !important;
  margin-right: 10px !important;
  overflow: scroll;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  text-overflow: ellipsis;
}

.fieldLabel::-webkit-scrollbar {
  display: none;
}

.fieldValue {
  flex: 1 1 auto !important;
}

.notSet input {
  display: flex;
  align-items: center;
  height: 75px;
}

.fieldCheckbox {
  margin: 0 0 0 5px !important;
}

.fieldCompareCheckbox {
  margin: 0!important;
}

.nonIdealState {
  height: auto !important;
}
