.navMenu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.navMenu li a {
  display: block; /* Used to fill the wrapping <li> */
  text-decoration: none;
  padding: 5px;
  color: #FFF;
}

.navMenu li a:hover {
  color: #686868;
}

.activeNavMenuItem {
  background-color: #3A3A3A;
}

.activeNavMenuItem:hover {
  color: #FFF !important;
}

.subMenu {
  list-style: disclosure-closed;
}