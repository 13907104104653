.container {
  display: flex;
  height: calc(100vh - 65px);
  gap: 15px;
}

.content {
  flex-grow: 1;
  overflow-y: scroll;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
