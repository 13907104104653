.actionsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.tableCheckbox {
  margin: 0 !important;
}

.inlineInputs {
  display: flex;
  gap: 20px;
}
