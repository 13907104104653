.container {
  display: flex;
  gap: 15px;
}

.setupSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}
