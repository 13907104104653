.inputCheckbox {
  position: relative;
}

.inputCheckboxText {
  min-width: 50px;
}

.inputCheckboxButton {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  padding: 0;
  width: 0;
  height: 0;
}

.inputCheckboxYellowBackground {
  background-color: yellow;
}

.inputCheckboxTriangle {
  width: 0;
  height: 0;
  border-left: 24px solid transparent;
  position: absolute;
  top: 0;
  right: 0;
  border-top: 24px solid #707070;
}

.inputCheckboxTriangleUnchecked {
  border-top: 24px solid #707070;
}

.inputCheckboxTriangleChecked {
  border-top: 24px solid #a3a3a3;
}

.inputCheckboxIcon {
  top: 0;
  right: 0;
  position: absolute;
  width: 16px;
  font-size: 10px;
  color: #202020;
}
