.titleBar {
  position: sticky;
  top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  width: 100%;
  z-index: 1;
  border-bottom: 1px solid #787878;
  background-color: #EAEAEA;
}

:global(.dark) .titleBar {
  background-color: #2C2C2C;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
}

.titleColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
}

.titleLabel {
  font-size: 0.9em;
  margin-bottom: 5px;
}

.titleValue {
  font-size: 2em;
}

.titleValue.year {
  width: 75px;
}

.titleValue.car,
.titleValue.event {
  width: 50px;
}
