.calendar {
  background-color: #393433;
}

.historicalWeatherContainer {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.historicalWeatherContainer span {
  margin-bottom: 5px;
}

.historicalWeatherTitle {
  font-weight: bold;
}

.historicalWeatherItem {
  margin-left: 5px;
}

.trackImageContainer {
  position: relative;
  display: flex;
  flex: 1 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trackImage {
  fill-opacity: 0!important;
  stroke: white!important;
}

.arrowImageContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@keyframes rotate-wind {
  0% { transform: rotate(270deg); }
}

.arrowImage {
  color: white;
  fill: currentColor;
  pointer-events: none;
  animation: rotate-wind 0.3s;
  animation-timing-function: ease-out;
}

.arrowImageLine {
  stroke: white;
}

.titleBar {
  position: sticky;
  top: 30px;
  margin-top: -15px;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 2px;
  border-bottom: 1px solid #787878;
  z-index: 1;
  background-color: #EAEAEA;
}

:global(.dark) .titleBar {
  background-color: #2C2C2C;
}

.titleLabel {
  font-size: 0.9em;
  margin-bottom: 5px;
}

.titleValue {
  font-size: 2em;
}

.saveButton {
  margin-bottom: 10px;
}

.mainContainer {
  display: flex;
  gap: 15px;
  align-items: flex-start;
}

.nav {
  position: sticky;
  top: 130px;
  left: 0;
  text-overflow: ellipsis;
  border-radius: 2px;
  padding: 0 5px;
  width: 200px;
}

.sideNavContainer {
  padding: 5px;
  background-color: #f6f7f9;
}

:global(.dark) .sideNavContainer {
  background-color: #2F343C;
}

.mainForm {
  display: flex;
  flex-direction: column;
}

.envContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.envContainer label {
  display: flex!important;
  align-items: center!important;
}

.itemContainer {
  margin-bottom: 15px;
}

.accordionHeader {
  scroll-margin-top: 130px;
}

.sectionContainer {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #6C6C6C;
  border-radius: 0 0 3px 3px;
  padding: 15px;
}

.formGroup {
  margin-bottom: 0!important;
  justify-content: space-between!important;
  align-items: center!important;
}

.flex {
  flex: 1;
}

.flexSection {
  display: flex;
}

.envInput {
  display: flex;
  width: 500px;
}

.inputRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px;
}

.inputRow label {
  margin-bottom: 0!important;
}

.inputRow:nth-of-type(odd) {
  background-color: #f6f7f9;
}

:global(.dark) .inputRow:nth-of-type(odd) {
  background-color: #333;
}

.gridInputs {
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}
