.mainForm {
  display: flex;
  flex-direction: column;
}

.titleBar {
  position: sticky;
  top: 30px;
  margin-top: -15px;
  margin-bottom: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: calc(100vw);
  border-bottom: 1px solid #787878;
  z-index: 1;
  background-color: #f6f7f9
}

:global(.dark) .titleBar {
  background-color: #2F343C;
}

.titleLabel {
  font-size: 0.9em;
  margin-bottom: 5px;
}

.titleValue {
  font-size: 2em;
}

.saveButton {
  margin-bottom: 10px;
}

.mainContainer {
  display: flex;
  gap: 15px;
  align-items: flex-start;
}

.nav {
  position: sticky;
  top: 130px;
  left: 0;
  text-overflow: ellipsis;
  border-radius: 2px;
  padding: 0 5px;
  width: 200px;
}

.sideNavContainer {
  padding: 5px;
  background-color: #f6f7f9;
}

:global(.dark) .sideNavContainer {
  background-color: #2F343C;
}

.simContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.simContainer label {
  display: flex!important;
  align-items: center!important;
}

.itemContainer {
  margin-bottom: 15px;
}

.accordionHeader {
  scroll-margin-top: 130px;
}

.sectionContainer {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #6C6C6C;
  border-radius: 0 0 3px 3px;
  padding: 15px;
}

.formGroup {
  margin-bottom: 0!important;
  justify-content: space-between!important;
  align-items: center!important;
}

.flex {
  flex: 1;
}

.flexRow {
  display: flex;
}

.simInput {
  display: flex;
  width: 500px;
}

.inputRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px;
}

.inputRow label {
  margin-bottom: 0!important;
}

.inputRow:nth-of-type(odd) {
  background-color: #f6f7f9;
}

:global(.dark) .inputRow:nth-of-type(odd) {
  background-color: #333;
}

.segmentsTable {
  width: 100%;
}

:global(.dark) .segmentsTable {
  background: rgba(17, 20, 24, 0.3);
}

.segmentsTable tr:nth-child(odd) { 
  background-color: #f6f7f9; 
}

:global(.dark) .segmentsTable tr:nth-child(odd) { 
  background-color: #333; 
}

.gridInputs {
  display: grid;
  flex: 1;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.inline {
  flex: 1 1;
}

.outputCheckboxContainer {
  display: flex;
}

.outputCheckboxContainer label {
  margin-left: 20px;
}

.outputCheckboxName {
  width: 35px;
}