.dayPicker {
  margin: 0 !important;
  padding: 1em;
}

.dayPickerSelected,
.dayPickerButton:hover {
  background-color: #B8271C !important;
}

.quadSection {
  width: 250px;
}

.singleSection {
  width: 125px;
}

.marginRight {
  margin-right: 15px !important;
}

.inputLabel {
  padding: 5px;
  text-align: center;
}

.gridInputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}