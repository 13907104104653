.mainForm {
  width: 400px;
}

.selectFormGroup {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.tooltipTarget {
  display: inline-block !important;
}

.tooltip {
  max-width: 450px;
}

.tooltipContent code {
  background-color: #232323;
}
.tooltipContent.dark code {
  background-color: #BDBDBD;
}
