.formGroup {
  padding: 15px;
  margin: 0 !important;
}

.multiSelectGroup,
.numberGroup,
.selectGroup,
.textGroup {
  min-width: 250px;
}

.checkbox {
  margin: 0 !important;
  display: inline-flex;
}
